<template>
  <div>
    <!-- 面包屑 -->
    <bread-crumb :breadList="breadList"/>
    <!-- 步骤条 -->
    <div class="contentData">
      <el-steps :active="active" style="margin: 20px 0" align-center>
        <el-step title="基础信息" description></el-step>
        <el-step title="基地信息" description></el-step>
        <el-step title="活动信息" description></el-step>
        <el-step title="投入信息" description></el-step>
      </el-steps>
      <!-- 第一步 -->
      <el-button type="primary" @click="addList" v-show="active==1" style="margin-bottom: 1rem;">新增基地</el-button>
      <el-form
        hide-required-asterisk
        v-for="(items,indexs) in data.baseInfoList" :key="indexs"
        :model="data"
        :rules="rules"
        ref="personRecord0"
        label-width="170px"
        class="demo-data"
        style="padding: 1rem;background-color: rgba(25,116,255,0.18);margin-bottom: 1rem;"
        v-show="active==1"
      >
        <div class="title clearfix">
          <span class="first fl"></span>
          <span class="second fl">基地信息</span>
          <div style="text-align:right;padding:0 20px;" v-if="indexs !== 0">
            <el-button type="danger" round @click="_remove_baseInfo(indexs)">删除基地</el-button>
          </div>
        </div>
        <div class="sub-title">基地{{ indexs + 1 }}</div>
        <div class="sub-title sub-blue">生产用地{{ indexs + 1 }}</div>
        <el-form-item label="基地地址"  required>
          <div v-if="items.cityName">
            <div class='areaItem'>
              <el-select v-model="addressValue" disabled>
                <el-option :label="items.provinceName" value="1"></el-option>
              </el-select>
            </div>
            <div class='areaItem'>
              <el-select v-model="addressValue" disabled>
                <el-option :label="items.cityName" value="1" checked></el-option>
              </el-select>
            </div>
            <div class='areaItem'>
              <el-select v-model="addressValue" disabled>
                <el-option :label="items.districtName" value="1" checked></el-option>
              </el-select>
            </div>
            <div class='areaItem'>
              <el-select v-model="addressValue" disabled>
                <el-option :label="items.villageName" value="1" checked></el-option>
              </el-select>
            </div>
          </div>
          <villageAddressSelect
            v-else
            @villageInfo="villageInfo"
            :cityDistrictVillage="cityDistrictVillage"
            :Getindex="indexs"
          >
          </villageAddressSelect>
        </el-form-item>
        <div class="flex dataCommon">
          <el-form-item label="基地名称"  required>
            <el-input v-model="items.baseName" clearable></el-input>
          </el-form-item>
        </div>
        <el-form-item label="基地详细地址" required>
          <el-input v-model="items.baseAddress" clearable></el-input>
        </el-form-item>
        <div class="flex dataCommon">
          <el-form-item label="总面积(亩)"  required>
            <el-input v-model="items.baseArea" type="number" clearable>
              <template slot="append">亩</template>
            </el-input>
          </el-form-item>
          <el-form-item label="基地性质"  required>
            <el-select
              v-model="items.baseNature"
              value-key="id"
              @change="choseBaseType"
              placeholder="选择基地性质"
            >
              <el-option
                v-for="item in baseType"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        <!--        经纬度-->
        <div class="flex dataCommon">
          <el-form-item label="经度"  required>
            <el-input v-model="items.lat" type="number" clearable></el-input>
          </el-form-item>
          <el-form-item label="纬度"  required>
            <el-input v-model="items.lng" type="number" clearable></el-input>
          </el-form-item>
        </div>
        <!--        土地取得方式-->
        <el-form-item label="土地取得方式">
          <el-select v-model="items.methodAcquisition" placeholder="土地取得方式">
            <el-option label="租赁" value="1"></el-option>
            <el-option label="购买" value="2"></el-option>
          </el-select>
        </el-form-item>
        <!--        -->
        <div class="flex dataCommon">
          <el-form-item label="合同开始时间">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="items.contractStartDate"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="合同结束时间">
            <el-date-picker
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="items.contractEndDate"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <!--        -->
        <div class="flex dataCommon">
          <el-form-item label="甲方名称">
            <el-input v-model="items.partyaName"></el-input>
          </el-form-item>
          <el-form-item label="乙方名称">
            <el-input v-model="items.partybName"></el-input>
          </el-form-item>
        </div>
        <!--        -->
        <div class="flex dataCommon">
          <el-form-item label="基地分布">
            <el-checkbox-group v-model="items.baseDistribution" disabled>
              <el-checkbox :label="1" :checked='items.coreArea ? true : false'>核心区</el-checkbox>
              <el-checkbox :label="2" :checked='items.distributionArea ? true : false'>保护区和其他区域</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <!--        -->
        <div class="flex dataCommon" v-if="items.coreArea">
          <el-form-item label="核心区面积(亩)">
            <el-input v-model="items.coreArea" aria-placeholder="请输入核心区面积">
              <template slot="append">亩</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="flex dataCommon" v-if="items.distributionArea">
          <el-form-item label="其他区域(亩)">
            <el-input v-model="items.distributionArea" aria-placeholder="请输入保护区和其他区域之和">
              <template slot="append">亩</template>
            </el-input>
          </el-form-item>
        </div>
        <!--   土地类型     -->
        <div class="flex dataCommon">
          <el-form-item label="土地类型">
            <el-checkbox-group v-model="items.AreaChange" @change="getAreaChange($event,indexs)" disabled>
              <el-checkbox label="otherLandArea" :checked='items.otherLandArea ? true : false'>其他面积</el-checkbox>
              <el-checkbox label="dryFarmlandArea" :checked='items.dryFarmlandArea ? true : false'>旱田面积</el-checkbox>
              <el-checkbox label="paddyFieldArea" :checked='items.paddyFieldArea ? true : false'>水田面积</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <el-form-item :label="item==='dryFarmlandArea'?'旱田面积':item==='paddyFieldArea'?'水田面积':'其他面积'"
          v-for="(item,index) in items.AreaChange" :key="index+'a'">
          <el-input
            v-model="items[items.AreaChange[index]]" aria-placeholder="请输入核心区面积">
            <template slot="append">亩</template>
          </el-input>
        </el-form-item>
        <!--        -->
        <div class="flex dataCommon">
          <el-form-item label="基地平面照片" prop="planeImgUrl">
            <img :src="items.planeImg" alt="" v-if="items.planeImg">
            <uploadMutImg
              v-else
              :moduleList="moduleList"
              ref="planeImgUrl"
              @changeImgList="changePlaneImgUrl($event,indexs)"
            ></uploadMutImg>
          </el-form-item>
        </div>
        <div class="flex dataCommon">
          <el-form-item label="土地流转合同(选填)" prop="landTransferContract">
            <img :src="items.landContractImg" alt="" v-if="items.landContractImg">
            <uploadMutImg
              v-else
              :moduleList="moduleList"
              ref="landTransferContract"
              @changeImgList="changelandTransferContract($event,indexs)"></uploadMutImg>
          </el-form-item>
        </div>
        <div class="title clearfix">
          <span class="first fl"></span>
          <span class="second fl">配套设施</span>
        </div>
        <div class="flex dataCommon equipment">
          <el-form-item label="用地总面积（亩）">
            <el-input v-model="items.landArea" type="number" clearable>
              <template slot="append">亩</template>
            </el-input>
          </el-form-item>
          <el-form-item label="总建筑面积（m2）">
            <el-input v-model="items.buildArea" type="number" clearable>
              <template slot="append">m2</template>
            </el-input>
          </el-form-item>
        </div>
        <el-form-item label="楼栋数量">
          <el-input v-model.number="items.buildingNumber" type="number" clearable></el-input>
        </el-form-item>
        <el-button type="primary" style="margin-bottom: 2rem;" @click="addBuilding(indexs)">新增建筑</el-button>
        <div class="contentBg" style="margin: 0;width: 100%;margin-bottom: 2rem;"
             v-for="(item,index) in items.architectureInfoList" :key="index">
          <div class="person-btn flex" style="padding: 20px 10px">
            <h3>第{{index+1}}栋建筑信息</h3>
            <div style="text-align:right;padding:0 20px;" v-if="index !== 0">
              <el-button type="danger" round @click="_remove_architectureInfo(indexs, index)">删除建筑</el-button>
            </div>
          </div>
          <el-form-item label="建筑面积">
            <el-input v-model="item.area" type="number" clearable>
              <template slot="append">m2</template>
            </el-input>
          </el-form-item>
          <div class="title clearfix">
            <el-form-item label="建筑形式">
              <el-select v-model="item.modality" placeholder="请选择">
                <el-option label="板楼" :value="1"></el-option>
                <el-option label="塔楼" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="建筑层数">
              <el-input v-model="item.layers" type="number" clearable></el-input>
            </el-form-item>
          </div>
          <el-form-item label="建筑时间">
            <el-date-picker
              v-model="item.buildTime"
              type="month"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
              :editable="false">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="地上面积">
            <el-input v-model="item.groundArea" type="number" clearable>
              <template slot="append">m2</template>
            </el-input>
          </el-form-item>
          <el-form-item label="地下面积">
            <el-input v-model="item.undergroundArea" type="number" clearable>
              <template slot="append">m2</template>
            </el-input>
          </el-form-item>
          <el-form-item label="建设/备案手续是否齐全">
            <el-radio-group v-model="item.isComplete" type="number">
              <el-radio :label="false">不齐全</el-radio>
              <el-radio :label="true">齐全</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备案时间">
            <el-date-picker
              v-model="item.recordTime"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
              :editable="false">
            </el-date-picker>
          </el-form-item>

          <el-form-item label="是否有产权">
            <el-radio-group v-model="item.isProperty">
              <el-radio :label="false">否</el-radio>
              <el-radio :label="true">是</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="产权名称" v-if="item.isProperty === true">
            <el-input v-model="item.propertyName"  clearable></el-input>
          </el-form-item>
        </div>
        <div class="flex dataCommon equipment">
          <el-form-item label="其他配套设施总面积"
            :prop="`baseInfoList.${indexs}.otherArea`"
            :rules="rules[`baseInfoList.otherAreaType`]">
            <el-input v-model="items.otherArea" type="number" clearable>
              <template slot="append">m2</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="flex dataCommon equipment">
          <el-form-item label="配套设施类型">
            <el-button type="primary" @click="addenclosingwall(indexs)">围墙</el-button>
            <el-button type="primary" @click="addRoad(indexs)">道路</el-button>
            <el-button type="primary" @click="addSunburn(indexs)">晒场</el-button>
            <el-button type="primary" @click="addgreenhouse(indexs)">大棚</el-button>
            <el-button type="primary" @click="addother(indexs)">其他</el-button>
          </el-form-item>
        </div>
        <div v-for="(item,index) in items.facilitiesInfoList" :key="index+'b'">
          <div class="flex dataCommon equipment">
            <el-form-item :label="['','围墙','道路','晒场','大棚','其他'][item.type]+'面积（m2）'">
              <el-input v-model="item.area" type="number" clearable>
                <template slot="append">m2</template>
              </el-input>
            </el-form-item>
            <el-form-item :label="['','围墙','道路','晒场','大棚','其他'][item.type]+ '建设时间'">
              <el-date-picker
                v-model="item.buildTime"
                value-format="yyyy-MM"
                type="month"
                placeholder="选择日期"
                :editable="false">
              </el-date-picker>
            </el-form-item>
          </div>
          <div class="flex dataCommon equipment">
            <el-form-item :label="['','围墙','道路','晒场','大棚','其他'][item.type]+'是否备案'">
              <el-radio-group v-model="item.isRecord">
                <el-radio :label="false">否</el-radio>
                <el-radio :label="true">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="['','围墙','道路','晒场','大棚','其他'][item.type]+ '备案时间'" v-if="item.isRecord">
              <el-date-picker
                v-model="item.recordTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
                :editable="false">
              </el-date-picker>
            </el-form-item>
          </div>
        </div>

        <div class="title clearfix">
          <span class="first fl"></span>
          <span class="second fl">生产设备类型</span>
        </div>
        <!-- 生产设备 -->
        <el-button type="primary" @click="addNewequipment(indexs)">新增设备</el-button>
        <div class="contentBg" style="margin: 0;width: 100%;" v-for="(item,index) in items.equipmentInfoList"
             :key="index+ 'c'">
          <div class="title clearfix" style="padding: 1rem;">
            <span class="second fl">{{item.type === 1?'实验设备':'农机设备'}} {{index+1}}</span>
            <div style="text-align:right;padding:0 20px;" v-if="index !== 0">
              <el-button type="danger" round @click="_remove_equipmentInfo(indexs, index)">删除设备</el-button>
            </div>
          </div>
          <div class="flex dataCommon equipment">
            <el-form-item label="设备名称">
              <el-input v-model="item.name" clearable></el-input>
            </el-form-item>
            <el-form-item label="设备数量">
              <el-input v-model="item.number" type="number" clearable></el-input>
            </el-form-item>
          </div>
          <el-form-item label="生产设备种类">
            <el-radio-group v-model="item.type">
              <el-radio :label="1">实验设备</el-radio>
              <el-radio :label="2">农机设备</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
      <!-- 第二步 -->
      <el-form
        hide-required-asterisk
        :model="data"
        :rules="rules"
        ref="personRecord1"
        label-width="222px"
        class="demo-data"
        v-show="active==0"
      >
        <div class="title clearfix">
          <span class="first fl"></span>
          <span class="second fl">{{(type.value === 4 || type.value === 5)?'个人':'单位'}}信息</span>
        </div>
        <!-- 单位 -->
        <template v-if="type.value !== 4 && type.value !== 5">
          <div class="flex dataCommon">
            <el-form-item label="单位名称" prop="basisInfo.name" required>
              <el-input v-model="data.basisInfo.name" maxlength="30" clearable></el-input>
            </el-form-item>
            <el-form-item label="单位级别" prop="basisInfo.name" required>
              <el-select v-model="data.basisInfo.level" placeholder="请选择">
                <el-option label="国家级" :value="1"></el-option>
                <el-option label="省级" :value="2"></el-option>
                <el-option label="地级市" :value="3"></el-option>
                <el-option label="其他" :value="4"></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="flex dataCommon">
            <el-form-item label="法人姓名" prop="basisInfo.legalPerson" required>
              <el-input v-model="data.basisInfo.legalPerson" maxlength="30" clearable></el-input>
            </el-form-item>
            <el-form-item label="信用代码" prop="basisInfo.societyCode" required>
              <el-input v-model="data.basisInfo.societyCode" maxlength="30" clearable></el-input>
            </el-form-item>
          </div>

          <div class="flex dataCommon">
            <el-form-item label="法人身份证号" prop="basisInfo.legalIdentityCard" required>
              <el-input v-model="data.basisInfo.legalIdentityCard" maxlength="30" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系人电话" prop="basisInfo.phoneNumber" required>
              <el-input v-model="data.basisInfo.phoneNumber" maxlength="30" clearable></el-input>
            </el-form-item>
          </div>

          <el-form-item label="邮箱" required  prop="basisInfo.email" >
            <el-input v-model="data.basisInfo.email" maxlength="30" clearable></el-input>
          </el-form-item>
          <div class="flex dataCommon">
            <el-form-item label="注册地址" prop="basisInfo.RegistNanfanAddress" required>
              <div v-if="data.basisInfo.registerCityName">
                <div class='areaItem'>
                  <el-select v-model="addressValue" disabled>
                    <el-option :label="data.basisInfo.registerProvinceName" value="1"></el-option>
                  </el-select>
                </div>
                <div class='areaItem'>
                  <el-select v-model="addressValue" disabled>
                    <el-option :label="data.basisInfo.registerCityName" value="1" checked></el-option>
                  </el-select>
                </div>
                <div class='areaItem'>
                  <el-select v-model="addressValue" disabled>
                    <el-option :label="data.basisInfo.registerDistrictName" value="1" checked></el-option>
                  </el-select>
                </div>
              </div>
              <villageAddressSelectX
                v-else
                @villageInfo="getNanfanRegister"
                :isVillage='false'
                :cityDistrictVillage="NanfanAddress">
              </villageAddressSelectX>
            </el-form-item>
          </div>
          <el-form-item label="详细地址" prop="basisInfo.unitAddress" required>
            <el-input v-model="data.basisInfo.unitAddress" maxlength="30" clearable></el-input>
          </el-form-item>
        </template>

        <!-- 南繁负责人信息 -->
        <template v-if="type.value !== 4 && type.value !== 5">
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">南繁负责人信息</span>
          </div>
          <div class="flex dataCommon">
            <el-form-item label="联系人姓名" prop="basisInfo.contactName" required>
              <el-input v-model="data.basisInfo.contactName" maxlength="5" clearable></el-input>
            </el-form-item>
            <!--            -->
            <el-form-item label="身份证号" prop="basisInfo.name" required>
              <el-input v-model="data.basisInfo.identityCard" maxlength="30" clearable></el-input>
            </el-form-item>
            <!--            -->
          </div>
          <div class="flex dataCommon">
            <el-form-item label="联系电话" prop="basisInfo.legalPerson" required>
              <el-input v-model="data.basisInfo.contactNumber" maxlength="30" clearable></el-input>
            </el-form-item>
          </div>
          <div class="flex dataCommon">
            <el-form-item label="职务职称" prop="basisInfo.position" required>
              <el-input
                v-model="data.basisInfo.position"
                maxlength="30"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex dataCommon">
            <el-form-item label="南繁地址" prop="basisInfo.changeNanfanAddress" required>
              <div v-if="data.basisInfo.nanfanCityName">
                <div class='areaItem'>
                  <el-select v-model="addressValue" disabled>
                    <el-option :label="data.basisInfo.nanfanProvinceName" value="1"></el-option>
                  </el-select>
                </div>
                <div class='areaItem'>
                  <el-select v-model="addressValue" disabled>
                    <el-option :label="data.basisInfo.nanfanCityName" value="1" checked></el-option>
                  </el-select>
                </div>
                <div class='areaItem'>
                  <el-select v-model="addressValue" disabled>
                    <el-option :label="data.basisInfo.nanfanDistrictName" value="1" checked></el-option>
                  </el-select>
                </div>
              </div>
              <villageAddressSelectX
                v-else
                @villageInfo="getNanfanAddress"
                :cityDistrictVillage="NanfanAddress">
              </villageAddressSelectX>
            </el-form-item>
          </div>
          <el-form-item label="详细地址" prop="basisInfo.nanfanAddress" required>
            <el-input v-model="data.basisInfo.nanfanAddress" maxlength="30" clearable></el-input>
          </el-form-item>
        </template>
        <!-- 个人 -->
        <template v-if="type.value === 4 || type.value === 5">
          <div class="flex dataCommon">
            <el-form-item label="姓名" prop="basisInfo.name" required>
              <el-input v-model="data.basisInfo.name" maxlength="30" clearable></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="basisInfo.contactNumber" required>
              <el-input
                v-model="data.basisInfo.contactNumber"
                :disabled="type.value==1"
                maxlength="30"
                clearable
              ></el-input>
            </el-form-item>
          </div>
          <div class="flex dataCommon">
            <el-form-item label="身份证号" prop="basisInfo.identityCard" required>
              <el-input v-model="data.basisInfo.identityCard" maxlength="30" clearable></el-input>
            </el-form-item>
          </div>
          <div class="flex dataCommon">
            <el-form-item
              label="邮箱"
              prop="basisInfo.email"
              required
            >
              <el-input v-model="data.basisInfo.email" clearable></el-input>
            </el-form-item>
          </div>

          <el-form-item
            label="南繁地址"
            prop="basisInfo.changeNanfanAddress"
            :rules="rules[`basisInfo.personnelInfoList.changeNanfanAddress`]"
            required
          >
            <div v-if="data.basisInfo.nanfanCityName">
              <div class='areaItem'>
                <el-select v-model="addressValue" disabled>
                  <el-option :label="data.basisInfo.nanfanProvinceName" value="1"></el-option>
                </el-select>
              </div>
              <div class='areaItem'>
                <el-select v-model="addressValue" disabled>
                  <el-option :label="data.basisInfo.nanfanCityName" value="1" checked></el-option>
                </el-select>
              </div>
              <div class='areaItem'>
                <el-select v-model="addressValue" disabled>
                  <el-option :label="data.basisInfo.nanfanDistrictName" value="1" checked></el-option>
                </el-select>
              </div>
            </div>
            <villageAddressSelectX
              v-else
              @villageInfo="getNanfanAddress"
              :isVillage="false"
              :cityDistrictVillage="NanfanAddress">
            </villageAddressSelectX>
          </el-form-item>
          <el-form-item
            label="详细地址"
            prop="basisInfo.nanfanAddress"
            :rules="rules[`basisInfo.personnelInfoList.nanfanAddress`]"
            required
          >
            <el-input v-model="data.basisInfo.nanfanAddress" clearable></el-input>
          </el-form-item>
        </template>
        <div class="contentBg" v-if="data.basisInfo.isReplace===1" style="padding: 20px 0">
          <el-form-item label="委托人信息" prop="basisInfo.replaceName" required>
            <el-input
              v-model="data.basisInfo.replaceName"
              placeholder="单位名称或个人姓名"
              maxlength="30"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="basisInfo.replaceContact" required>
            <el-input v-model="data.basisInfo.replaceContact" maxlength="30" clearable></el-input>
          </el-form-item>
          <el-form-item label="常用地址" prop="basisInfo.replaceAddress" required>
            <el-input v-model="data.basisInfo.replaceAddress" maxlength="30" clearable></el-input>
          </el-form-item>
        </div>
        <template>
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">人员信息</span>
          </div>
          <el-form-item label="管理人员数">
            <el-input v-model="data.basisInfo.managePersonalNumber" type="number" maxlength="18" clearable></el-input>
          </el-form-item>
          <el-form-item label="专业技术人员数">
            <el-input v-model="data.basisInfo.skillPersonalNumber" type="number" maxlength="18" clearable></el-input>
            <span style="margin-left: 2rem;">
              海南工人数 <span style="margin-right: .5rem;"></span> <el-input v-model="data.basisInfo.hainanWorkerNumber" type="number"  maxlength="18" clearable></el-input>
            </span>
          </el-form-item>
          <el-form-item label="外省工人">
            <el-input v-model="data.basisInfo.otherWorkerNumber" type="number" maxlength="18" clearable></el-input>
            <span style="margin-left: 2rem;">
              工人总数  <span style="margin-right: .5rem;"></span> <el-input v-model="data.basisInfo.workerNumber" :disabled="true"  type="number" maxlength="18" clearable></el-input>
            </span>
          </el-form-item>
          <el-form-item label="其他人员数">
            <el-input v-model="data.basisInfo.otherPersonalNumber" type="number" maxlength="18" clearable></el-input>
            <span style="margin-left: 2rem;">
              从事南繁活动总人数  <span style="margin-right: .5rem;"></span> <el-input v-model="data.basisInfo.personalNumber" :disabled="true" type="number" maxlength="18" clearable></el-input>
            </span>
          </el-form-item>
        </template>
        <!--        重要人员信息-->
        <template>
          <el-button type="primary" @click="addPerson">新增人员</el-button>
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">重要人员信息（选填）</span>
          </div>
          <!--          -->
          <div v-for="(itms,idxs) in data.basisInfo.personnelInfoList" :key="idxs + 'as'" class="importantePerson">
            <el-form-item label="重要人员身份">
              <el-select v-model="itms.identity" placeholder="请选择">
                <el-option label="院士" :value="1"></el-option>
                <el-option label="教授(研究员)" :value="2"></el-option>
                <el-option label="课题组负责人" :value="3"></el-option>
                <el-option label="基地负责人" :value="4"></el-option>
                <el-option label="其他重要人员" :value="5"></el-option>
              </el-select>
              <span style="margin-left: 2rem;">
              职务职称 <el-input v-model="itms.position" maxlength="18" clearable></el-input>
            </span>
            </el-form-item>

            <el-form-item label="姓名">
              <el-input v-model="itms.name" maxlength="18" clearable></el-input>
              <span style="margin-left: 2rem;">
              联系方式 <el-input v-model="itms.phoneNumber" maxlength="18" clearable></el-input>
            </span>
            </el-form-item>
            <el-form-item label="南繁活动开始时间">
              <el-date-picker
                v-model="itms.activityStartDate"
                type="month"
                format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="南繁活动结束时间">
              <el-date-picker
                v-model="itms.activityEndDate"
                type="month"
                format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>

          </div>
        </template>
      </el-form>
      <!-- 第三步 -->
      <el-form
        hide-required-asterisk
        :model="data"
        :rules="rules"
        ref="personRecord2"
        label-width="140px"
        class="demo-data"
        v-show="active == 2 && direction.value !== 2"
      >
        <template>
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">活动信息</span>
          </div>
          <div class="contentBg">
            <div class="person-btn flex" style="padding: 20px 10px">
              <div style="width:85%;text-align:center">
                <el-button
                  round
                  :style="{backgroundColor:initIndex1!==index ? '':'#E8EAF0'}"
                  v-for="(item,index)  in data.cropInfoList"
                  @click="initIndex1=index"
                  :key="index"
                >作物{{index+1}}
                </el-button>
              </div>
              <div style="width:10%">
                <el-button type="primary" round @click="_addMessage">新增作物</el-button>
              </div>
            </div>
            <!--            1-->
            <div
              v-for="(item,index) in data.cropInfoList"
              :key="index "
              v-show="index===initIndex1"
            >
              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label="作物名称"
                  :prop="`cropInfoList.${index}.cropName`"
                  :rules="rules[`cropInfoList.cropType`]"
                  required
                >
                  <el-select v-model="item.cropName" placeholder="请选择"
                      @change="changecropName($event,index)">
                    <el-option
                      v-for="item in cropInfoListType"
                      :key="item.id+Math.random()"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label
                  v-if="item.cropId == 13"
                  :rules="[{ message: '请填写其他作物物类型', required: true }]"
                  :prop="`cropInfoList.${index}.otherCropType`"
                >
                  <el-input
                    v-model="item.cropName"
                    placeholder="请填写其他作物物类型"
                    maxlength="11"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label="品种数"
                  required
                  :prop="`cropInfoList.${index}.varietiesNumber`"
                  :rules="rules[`cropInfoList.varietiesNumberType`]"
                  type="number">
                  <el-input v-model="item.varietiesNumber" type="number" maxlength="11" placeholder="请输入" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="育种组合数"
                  required
                  :prop="`cropInfoList.${index}.breedingNumber`"
                  :rules="rules[`cropInfoList.breedingNumberType`]"
                  type="number">
                  <el-input v-model="item.breedingNumber" type="number" maxlength="11" placeholder="请输入" clearable>
                  </el-input>
                </el-form-item>
              </div>
              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label="项目 "
                  required
                  type="number">
                  <el-select v-model="item.project" placeholder="请选择" value="1">
                    <el-option label="加代" :value="1"></el-option>
                    <el-option label="扩繁" :value="2"></el-option>
                    <el-option label="制种" :value="3"></el-option>
                    <el-option label="鉴定" :value="4"></el-option>
                    <el-option label="资源保存" :value="5"></el-option>
                    <el-option label="其他" :value="6"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <!--              -->
              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label="是否转基因"
                  :prop="`cropInfoList.${index}.isTransgene`"
                  :rules="rules[`cropInfoList.isTransgene`]"
                  required
                >
                  <el-select v-model="item.isTransgene" placeholder="请选择">
                    <el-option label="是" :value="true"></el-option>
                    <el-option label="否" :value="false"></el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="flex dataCommon" style="width:80%">
                <el-form-item label="种植开始时间" required>
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    v-model="item.plantStartTime"
                    placeholder="请选择时间"
                  ></el-date-picker>
                </el-form-item>

                <el-form-item label="种植结束时间" required>
                  <el-date-picker
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    v-model="item.plantEndTime"
                    placeholder="请选择时间"
                  ></el-date-picker>
                </el-form-item>
              </div>

              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label
                  v-if="item.activityType===0"
                  :rules="[{ message: '请填写其他活动类型', required: true }]"
                  :prop="`cropInfoList.${index}.otherActivityType`"
                >
                  <el-input
                    v-model="item.otherActivityType"
                    placeholder="请填写其他活动类型"
                    maxlength="11"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex dataCommon" style="width:80%">
                <el-form-item label="种植面积">
                  <el-input v-model="item.plantArea" type="number" maxlength="11" placeholder="请输入" clearable>
                    <template slot="append">亩</template>
                  </el-input>
                </el-form-item>
                <div style="text-align:right;padding:0 20px;" v-if="index !==0">
                  <el-button type="danger" round @click="_remove_message(index)">删除</el-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-form
          :model="data"
          hide-required-asterisk
          :rules="rules"
          ref="personRecord3"
          label-width="200px"
          class="demo-data"
          v-show="active == 2 && direction.value !== 2"
        >
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">成果信息</span>
          </div>
          <div
            class="person-btn flex"
            style="padding: 20px 10px;background-color:#f6f6f6;margin-bottom:2px;"
          >
            <div style="width:85%;text-align:center">
              <el-button
                round
                :style="{backgroundColor:initIndex2!==index ? '':'#E8EAF0'}"
                v-for="(item,index)  in data.resultInfoList"
                @click="initIndex2=index"
                :key="index"
              >成果信息{{index+1}}
              </el-button>
            </div>
            <div style="width:10%">
              <el-button type="primary" round @click="addResult">新增成果</el-button>
            </div>
          </div>
          <!-- 成果信息-->
          <div class="contentBg" style="width:100%">
            <div v-for="(item,index) in data.resultInfoList" :key="index" v-show="index===initIndex2">
              <div class="flex dataCommon" style="width:80%;padding-top:20px">
                <el-form-item
                  label="作物类型"
                  :prop="`resultInfoList.${index}.cropName`"
                  :rules="rules[`cropInfoList.cropType`]"
                  required
                >
                  <el-select v-model="item.cropName" placeholder="请选择" @change="changeRecropName($event,index)">
                    <el-option
                      v-for="item in cropInfoListType"
                      :key="item.id+Math.random()"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label
                  v-if="item.cropId == 13"
                  :prop="`resultInfoList.${index}.cropName`"
                  :rules="[{ message: '请填写其他作物物类型', required: true }]"
                >
                  <el-input
                    v-model="item.cropName"
                    placeholder="请填写其他作物物类型"
                    maxlength="11"
                    clearable
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div style="margin:25px 65px;justify-content : space-between;" class="flex">
                <el-button type="primary" icon="el-icon-plus" @click="_addVariety(item)">新增品种</el-button>
                <el-button type="danger" v-if="index!=0" @click="_remove_result(index)">删除</el-button>
              </div>
              <div
                style="border:1px solid rgba(208,213,226,1);padding:5px;width:90%;margin:10px auto;padding:10px 0;"
                v-for="(val,idx) in item.varietiesInfoList"
                :key="idx"
              >
                <div
                  :style="`margin-left:-2px;color:#fff;width:102px;height:40px;line-height:33px;text-align:center;background:url(${require('@/assets/images/nfRecord/circle.png')}) no-repeat left top/cover`"
                >品种{{idx+1}}
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item
                    label="品种名称"
                    :prop="`resultInfoList.${index}.varietiesInfoList.${idx}.name`"
                    :rules="rules[`varietiesInfoList.varietyName`]"
                    required
                  >
                    <el-input v-model="val.name" maxlength="11" clearable></el-input>
                  </el-form-item>
                  <el-form-item
                    label="审定机构性质（选填）"
                    required
                  >
                  <!-- :prop="`resultInfoList.${index}.varietiesInfoList.${idx}.reviewName`"
                    :rules="rules[`varietiesInfoList.reviewName`]" -->
                    <el-select v-model.number="val.nature" placeholder="请选择">
                      <el-option label="国审" :value="1"></el-option>
                      <el-option label="省审" :value="2"></el-option>
                      <el-option label="备案" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item label="上一年度销售额（选填）">
                    <el-input
                      v-model.number="val.salesVolume"
                      type="number"
                      maxlength="11"
                      clearable
                    >
                      <template slot="append">万元</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flex dataCommon inp-width" style="width:50%">
                  <el-form-item label="累计推广面积-海南（选填）">
                    <el-input v-model.number="val.hainanArea" maxlength="11" type="number" clearable>
                      <template slot="append">亩</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="累计推广面积-全国（选填）">
                    <el-input
                      v-model.number="val.countryArea"
                      maxlength="11"
                      type="number"
                      clearable
                    >
                      <template slot="append">亩</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item label="其他推广情况（选填）">
                    <el-input
                      v-model="val.otherSituation"
                      maxlength="100"
                      type="textarea"
                      :rows="4"
                      placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </div>
                <div style="text-align:right;padding:0 60px;" v-if="idx !==0">
                  <el-button type="danger" round @click="_remove_variety(item,idx)">删除品种</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </el-form>
<!--      种子生产-->
      <el-form
        :model="data"
        hide-required-asterisk  
        :rules="rules"
        ref="personRecord2"
        label-width="140px"
        class="demo-data"
        v-show="active == 2 && direction.value === 2"
      >
        <template>
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">活动信息</span>
          </div>
          <div class="contentBg">
            <div class="person-btn flex" style="padding: 20px 10px">
              <div style="width:85%;text-align:center">
                <el-button
                  round
                  :style="{backgroundColor:initIndex1!==index ? '':'#E8EAF0'}"
                  v-for="(item,index)  in data.cropInfoList"
                  @click="initIndex1=index"
                  :key="index"
                >作物{{index+1}}
                </el-button>
              </div>
              <div style="width:10%">
                <el-button type="primary" round @click="_addMessage">新增作物</el-button>
              </div>
            </div>
            <!--            1-->
            <div
              v-for="(item,index) in data.cropInfoList"
              :key="index "
              v-show="index===initIndex1"
            >
              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label="作物名称"
                  :prop="`cropInfoList.${index}.cropName`"
                  :rules="rules[`cropInfoList.cropType`]"
                  required
                >
                  <el-select v-model="item.cropName" placeholder="请选择"
                      @change="changecropName($event,index)">
                    <el-option
                      v-for="item in cropInfoListType"
                      :key="item.id+Math.random()"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label
                  v-if="item.cropId == 13"
                  :rules="[{ message: '请填写其他作物物类型', required: true }]"
                  :prop="`cropInfoList.${index}.otherCropType`"
                >
                  <el-input
                    v-model="item.cropName"
                    placeholder="请填写其他作物物类型"
                    maxlength="11"
                    clearable
                  ></el-input>
                </el-form-item>
              </div>
              <!--              -->

              <div class="flex dataCommon" style="width:80%">
                <el-form-item
                  label="制种面积"
                >
                  <el-input v-model="item.plantArea" type="number" maxlength="11" clearable>
                    <template slot="append">亩</template>
                  </el-input>

                </el-form-item>
                <el-form-item
                  label="预估产量"
                >
                  <el-input v-model="item.yield" type="number" maxlength="11" clearable>
                    <template slot="append">万千克</template>
                  </el-input>
                </el-form-item>

                <div style="text-align:right;padding:0 20px;" v-if="index !==0">
                  <el-button type="danger" round @click="_remove_message(index)">删除</el-button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-form
          :model="data"
          :rules="rules"
          hide-required-asterisk
          ref="personRecord3"
          label-width="200px"
          class="demo-data"
          v-show="active == 2 && direction.value !== 2"
        >
          <!--        v-show="active==3 "-->
          <div class="title clearfix">
            <span class="first fl"></span>
            <span class="second fl">成果信息</span>
          </div>
          <div class="person-btn flex" style="padding: 20px 10px;background-color:#f6f6f6;margin-bottom:2px;">
            <div style="width:85%;text-align:center">
              <el-button
                round
                :style="{backgroundColor:initIndex2!==index ? '':'#E8EAF0'}"
                v-for="(item,index)  in data.resultInfoList"
                @click="initIndex2=index"
                :key="index"
              >成果信息{{index+1}}
              </el-button>
            </div>
            <div style="width:10%">
              <el-button type="primary" round @click="addResult">新增成果</el-button>
            </div>
          </div>
          <!-- 成果信息-->
          <div class="contentBg" style="width:100%">
            <div v-for="(item,index) in data.resultInfoList" :key="index" v-show="index===initIndex2">
              <div class="flex dataCommon" style="width:80%;padding-top:20px">
                <el-form-item
                  label="作物类型"
                  :prop="`resultInfoList.${index}.cropName`"
                  :rules="rules[`cropInfoList.cropType`]"
                  required
                >
                  <el-select v-model="item.cropName" placeholder="请选择" @change="changeRecropName($event,index)">
                    <el-option
                      v-for="item in cropInfoListType"
                      :key="item.id+Math.random()"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label
                  v-if="item.cropId == 13"
                  :prop="`resultInfoList.${index}.cropName`"
                  :rules="[{ message: '请填写其他作物物类型', required: true }]"
                >
                  <el-input
                    v-model="item.cropName"
                    placeholder="请填写其他作物物类型"
                    maxlength="11"
                    clearable
                    required
                  ></el-input>
                </el-form-item>
              </div>
              <div style="margin:25px 65px;justify-content : space-between;" class="flex">
                <el-button type="primary" icon="el-icon-plus" @click="_addVariety(item)">新增品种</el-button>
                <el-button type="danger" v-if="index!=0" @click="_remove_result(index)">删除</el-button>
              </div>
              <div
                style="border:1px solid rgba(208,213,226,1);padding:5px;width:90%;margin:10px auto;padding:10px 0;"
                v-for="(val,idx) in item.varietiesInfoList"
                :key="idx">
                <div :style="`margin-left:-2px;color:#fff;width:102px;height:40px;line-height:33px;text-align:center;background:url(${require('@/assets/images/nfRecord/circle.png')}) no-repeat left top/cover`"
                >品种{{idx+1}}
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item
                    label="品种名称"
                    :prop="`resultInfoList.${index}.varietiesInfoList.${idx}.name`"
                    :rules="rules[`varietiesInfoList.varietyName`]"
                    required
                  >
                    <el-input v-model="val.name" maxlength="11" clearable></el-input>
                  </el-form-item>
                  <el-form-item
                    label="审定机构性质（选填）"
                    required
                  >
                  <!--  :prop="`resultInfoList.${index}.varietiesInfoList.${idx}.reviewName`"
                    :rules="rules[`varietiesInfoList.reviewName`]" -->
                    <el-select v-model.number="val.nature" placeholder="请选择">
                      <el-option label="国审" :value="1"></el-option>
                      <el-option label="省审" :value="2"></el-option>
                      <el-option label="备案" :value="3"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item label="上一年度销售额（选填）">
                    <el-input
                      v-model.number="val.salesVolume"
                      type="number"
                      maxlength="11"
                      clearable
                    >
                      <template slot="append">万元</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item label="累计推广面积-海南（选填）">
                    <el-input v-model.number="val.hainanArea" maxlength="11" type="number" clearable>
                      <template slot="append">亩</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="累计推广面积-全国（选填）">
                    <el-input
                      v-model.number="val.countryArea"
                      maxlength="11"
                      type="number"
                      clearable
                    >
                      <template slot="append">亩</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="flex dataCommon" style="width:80%">
                  <el-form-item label="其他推广情况（选填）">
                    <el-input
                      v-model="val.otherSituation"
                      maxlength="100"
                      type="textarea"
                      :rows="4"
                      placeholder="请输入内容"
                    ></el-input>
                  </el-form-item>
                </div>
                <div style="text-align:right;padding:0 60px;" v-if="idx !==0">
                  <el-button type="danger" round @click="_remove_variety(item,idx)">删除品种</el-button>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </el-form>
      <!-- 第四步 -->
      <el-form
        :model="data"
        :rules="rules"
        hide-required-asterisk
        ref="personRecord3"
        label-width="170px"
        class="demo-data"
        v-show="active==3"
      >
        <!--        v-show="active==3"-->
        <div class="title clearfix">
          <span class="first fl"></span>
          <span class="second fl">投入信息</span>
        </div>
        <div>
          <div class="flex dataCommon">
            <div class="title clearfix">
              <span class="first fl"></span>
              <span class="second fl">科研投入</span>
            </div>
          </div>
          <div class="flex dataCommon">
            <el-form-item label="科研生产投入">
              <el-input v-model.number="data.investmentInfo.scientific">
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="flex dataCommon">
            <div class="title clearfix">
              <span class="first fl"></span>
              <span class="second fl">财政投入</span>
            </div>
          </div>

          <div class="flex dataCommon">
            <el-form-item label="基础设施投入">
              <el-input v-model.number="data.investmentInfo.facilities">
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="管理投入">
              <el-input v-model.number="data.investmentInfo.manage">
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
          </div>

          <br>
          <el-form-item label="其他投入">
            <el-input v-model.number="data.investmentInfo.other">
              <template slot="append">万元</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <!-- 按钮 -->
      <div class="submit" style="margin-top:20px">
        <el-button
          class="submitData"
          v-if="active <= 3 && active>=1"
          :class="submitStatus?'':'active'"
          @click="stepTo(-1)"
          style="background-color:#fff;color:blue;border:1px solid blue"
        >上一步
        </el-button>
        <el-button
          class="submitData"
          v-if="active < 3"
          :class="submitStatus?'':'active'"
          @click="stepTo(1)"
        >下一步
        </el-button>
        <el-button
          class="submitData"
          v-if="active==3"
          :class="submitStatus?'':'active'"
          @click="addSubmit()"
        >提交
        </el-button>
      </div>
      <!-- 新增基地 -->
      <el-dialog title="新增基地" :visible.sync="dialogFormVisible">
        <el-form :model="addBaseInfo" hide-required-asterisk :rules="baseInfoListRules" ref="addBaseInfo">
          <el-form-item label="基地名称" prop="baseName" :label-width="formLabelWidth" required>
            <el-input v-model="addBaseInfo.baseName" maxlength="10" clearable></el-input>
          </el-form-item>
          <el-form-item label="所属单位" prop="company" :label-width="formLabelWidth" required>
            <el-input v-model="addBaseInfo.company" maxlength="10" clearable></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="person" :label-width="formLabelWidth" required>
            <el-input v-model="addBaseInfo.person" maxlength="10" clearable></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="personPhone" :label-width="formLabelWidth" required>
            <el-input v-model="addBaseInfo.personPhone" clearable maxlength="11"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSureBase">新增</el-button>
        </div>
      </el-dialog>
      <div style="text-align:center;color:red;font-size:25px" v-show="active==4">
        <i class="el-icon-check"></i> 恭喜你完成提交
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import breadCrumb from '@/component/breadcrumb/breadcrumb.vue';
  // 下来选择框数据
  import {
    role_list,
    baseType,
    nanfanType,
    buildType,
    cropInfoListType,
    activityType,
    breadLists,
    laborUnitPrice
  } from './datalist';
  import {
    Upload,
    Loading,
    Message,
    DatePicker,
    Dialog,
    MessageBox,
    Alert
  } from 'element-ui';
  import villageAddressSelect from './threeAddress.vue';
  import villageAddressSelectX from '@/component/villageAddressSelectX/index.vue';
  import addressSelect from '@/component/addressSelect/index.vue';
  import { yyyymmdd, filter_empty_value } from '@/utils/utils';
  import uploadMutImg from '@/component/uploadMutImgX/index.vue';
  // 解析excel插件
  import XLSX from 'xlsx';

  Vue.use(Upload);
  Vue.use(Loading);
  Vue.use(DatePicker);
  Vue.use(Dialog);
  // 校验规则
  const validateName = (rule, value, callback) => {
    let msg = '';
    if (rule.field === 'name') {
      msg = '请输入姓名';
    } else if (rule.field === 'person') {
      msg = '请输入负责人姓名';
    }
    if (!value) {
      callback(new Error(msg));
      return;
    }
    const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (!reg.test(value)) {
      callback(new Error('只能输入汉字或者英文'));
    }
    callback();
  };
  const validateChinese = (rule, value, callback) => {
    const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
    if (!reg.test(value)) {
      callback(new Error('只能输入汉字或者英文'));
    }
    callback();
  };
  const validateIdentityCard = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入身份证号'));
      return;
    }
    const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
    if (!reg.test(value)) {
      callback(new Error('请输入正确的身份证号'));
    }
    callback();
  };
  const validatePhone = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入手机号码'));
      return;
    }
    const reg = /^1[3456789]\d{9}$/;
    if (!reg.test(value)) {
      callback(new Error('请输入正确的手机号码'));
    }
    callback();
  };
  const validateAddress = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入南繁地址'));
      return;
    }
    const reg = /^[a-zA-Z\u4e00-\u9fa5][a-zA-Z0-9\u4e00-\u9fa5]*$/;
    if (!reg.test(value)) {
      callback(new Error('只能输入中英文和数字, 且数字不能开头'));
    }
    callback();
  };
  const validateArea = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入面积'));
      return;
    }
    const reg = /^\d*\.{0,1}\d{0,2}$/;
    if (value <= 0) {
        callback(new Error('面积不能小于0'));
        return;
    } 
    callback();
  };
  const baseInfoListVillageId = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请选择基地地址'));
      return;
    }
    callback();
  };
  const validateBaseName = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入基地名称'));
      return;
    }
    const reg = /^[a-zA-Z\u4e00-\u9fa5][a-zA-Z0-9\u4e00-\u9fa5]*$/;
    if (!reg.test(value)) {
      callback(new Error('只能输入中英文和数字, 且数字不能开头'));
      return;
    }
    callback();
  };
  const validateEmail = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入邮箱'));
      return;
    }
    const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if (!reg.test(value)) {
      callback(new Error('请输入正确的邮箱'));
      return;
    }
    callback();
  };
  // 品种数判断
  const varietiesNumberType = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入品种数'));
      return;
    }
    const reg = /^(0|[1-9][0-9]*)$/;
    if (!reg.test(value)) {
      callback(new Error('品种数不能为负数且为整数'));
      return;
    }
    callback();
  };
  // 品种数判断
  const breedingNumberType = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入育种组合数'));
      return;
    }
    const reg = /^(0|[1-9][0-9]*)$/;
    if (!reg.test(value)) {
      callback(new Error('育种组合数不能为负数且为整数'));
      return;
    }
    callback();
  };
  const otherAreaType = (rule, value, callback) => {
    if (!value) {
      callback(new Error('请输入其他配套设施总面积'));
      return;
    }
    const reg = /^(0|[1-9][0-9]*)$/;
    if (!reg.test(value)) {
      callback(new Error('其他配套设施总面积不能为负数且为整数'));
      return;
    }
    callback();
  };

  // 引入基地信息list
  import baseInfoListLists from './baseInfo.js';
  // eslint-disable-next-line no-duplicate-imports
  import { facilitiesInfoList, equipmentInfoList } from './baseInfo.js';
  import basisInfo from './basisInfo';
  import cropInfoList from './cropInfoList';

  export default {
    name: 'nanfanbase',
    components: {
      villageAddressSelect,
      villageAddressSelectX,
      uploadMutImg,
      breadCrumb
    },
    data() {
      return {
        addressValue: '1',
        equipmentInfoList, // 生产设备信息列表
        facilitiesInfoList, // 配套设施类型
        contractTime: [], // 合同起止时间
        active: 0,
        breadList: [
          {
            name: '南繁备案'
          }
        ],
        dateValue: '',
        type: {},
        direction: {},
        initIndex: 0, //控制人员信息数据
        initIndex1: 0, //控制活动信息
        initIndex2: 0, //控制成果信息
        personNumber: 1,
        AreaChange: [],
        year: '',
        rules: {
          name: [
            {
              required: true,
              validator: validateName
            }
          ],
          identityCard: [{ required: true, validator: validateIdentityCard }],
          phone: [{ required: true, validator: validatePhone }],
          groupId: [{ required: true, message: '请选择所在组别' }],
          roleId: [{ required: true, message: '请选择角色' }],
          address: [{ required: true, validator: validateAddress }],
          'baseInfoList.baseArea': [{ validator: validateArea, required: true }],
          otherDistrictId: [{ message: '请选择外省地址', required: true }],
          'baseInfoList.baseAddress': [
            { message: '请输入具体的地址', required: true }
          ],
          'baseInfoList.cityId': [{ validator: baseInfoListVillageId, required: true }],
          'baseInfoList.baseName': [{ message: '请选择基地', required: true }],
          'baseInfoList.baseNature': [{ message: '请选择基地性质', required: true }],
          'basisInfo.year': [{ message: '请选择登记的时间', required: true }],
          'basisInfo.name': [
            { message: '请填写姓名或者单位名称', required: true }
          ],
          'baseInfoList.otherAreaType': [{ validator: otherAreaType, required: true }],
          'basisInfo.email': [
            { validator: validateEmail, required: true }
          ],
          'cropInfoList.varietiesNumberType': [
            { validator: varietiesNumberType, required: true }
          ],
          'cropInfoList.breedingNumberType': [
            { validator: breedingNumberType, required: true }
          ],
          'basisInfo.societyCode': [
            { message: '请填写信用代码', required: true }
          ],
          'basisInfo.phoneNumber': [{ validator: validatePhone, required: true }],
          'basisInfo.unitAddress': [
            { message: '请填写详细地址', required: true }
          ],
          'basisInfo.nanfanAddress': [
            { message: '请填写详细地址', required: true }
          ],
          'basisInfo.contactName': [
            { message: '请填写联系人姓名', required: true },
            { validator: validateChinese, required: true }
          ],
          'basisInfo.isReplace': [{ message: '请选择是否代繁', required: true }],
          'basisInfo.contactNumber': [
            { validator: validatePhone, required: true }
          ],
          'basisInfo.replaceName': [
            { message: '请填写委托人信息', required: true },
            { validator: validateChinese, required: true }
          ],
          'basisInfo.replaceContact': [
            { validator: validatePhone, required: true }
          ],
          'basisInfo.replaceAddress': [
            { message: '请填写常用地址', required: true }
          ],
          'basisInfo.legalIdentityCard': [
            { message: '请填写法人身份证号', required: true }
          ],
          'basisInfo.personnelInfoList.name': [
            { message: '请填写姓名', required: true },
            { validator: validateChinese, required: true }
          ],
          'basisInfo.personnelInfoList.identityCard': [
            { validator: validateIdentityCard, required: true }
          ],
          'basisInfo.personnelInfoList.phoneNumber': [
            { validator: validatePhone, required: true }
          ],
          'basisInfo.personnelInfoList.nanfanAddress': [
            { message: '请输入详情地址', required: true }
          ],
          'basisInfo.personnelInfoList.changeNanfanAddress': [
            { message: '请选择南繁地址', required: true }
          ],
          'basisInfo.personnelInfoList.role': [
            { message: '请选择角色', required: true }
          ],
          'basisInfo.personnelInfoList.position': [
            { message: '请填职务职称', required: true }
          ],
          'cropInfoList.activityType': [
            { message: '请填选择活动类型', required: true }
          ],
          'cropInfoList.activityEndDate': [
            { message: '请选择南繁活动时间', required: true }
          ],
          'cropInfoList.cropType': [
            { message: '请选择作物类型', required: true }
          ],
          'cropInfoList.isTransgene': [
            { message: '请选择是否转基因', required: true }
          ],
          'cropInfoList.area': [{ validator: validateArea, required: true }],
          'varietiesInfoList.reviewName': [
            { message: '请填写审定机构名称', required: true }
          ],
          'varietiesInfoList.varietyName': [
            { message: '请填写品种名称', required: true }
          ],
          'cropInfoList.leasePrice': [
            { message: '请填写租地价格', required: true }
          ],
          'cropInfoList.managementPersonalTotal': [
            { message: '请填写管理人员数量', required: true }
          ],
          'cropInfoList.nanfanAddress': [
            { message: '请填写南繁地点', required: true }
          ],

          'cropInfoList.laborUnitPrice': [
            { message: '请选择用工单价', required: true }
          ],
          'cropInfoList.laborTotal': [
            { message: '请填写用工数量', required: true }
          ],
          'basisInfo.legalPerson': [
            { message: '请填写法人姓名', required: true },
            { validator: validateChinese, required: true }
          ],
          'basisInfo.position': [
            { message: '请填写职务职称', required: true }
          ],
          'basisInfo.identityCard': [
            { required: true, validator: validateIdentityCard }
          ],
          'basisInfo.RegistNanfanAddress': [
            { message: '请选择注册地址', required: true }
          ],
          'basisInfo.changeNanfanAddress': [
            { message: '请选择注册地址', required: true }
          ],
          'basisInfo.provincialAddress': [
            { message: '请填写外省地址', required: true }
          ],
          'basisInfo.personalTotal': [
            { message: '请填写人员总数', required: true }
          ]
        },
        baseInfoListRules: {
          baseName: [
            {
              required: true,
              validator: validateBaseName
            }
          ],
          company: [
            {
              required: true,
              message: '请填写所属单位'
            }
          ],
          person: [
            {
              required: true,
              validator: validateName
            }
          ],
          personPhone: [
            {
              required: true,
              validator: validatePhone
            }
          ]
        },
        data: { // 表单数据来源
          baseInfoList: JSON.parse(JSON.stringify(baseInfoListLists)), // 基地信息
          basisInfo: JSON.parse(JSON.stringify(basisInfo)),  // j基础信息
          cropInfoList: JSON.parse(JSON.stringify(cropInfoList)), // 活动信息生产信息
          resultInfoList: [
            {
              cropName: "",
              cropId: "",
              // 作物名称
              sort: 0,
              varietiesInfoList: [
                // 品种信息List
                {
                  countryArea: null, // 累计推广面积-全国
                  hainanArea: null, //累计推广面积-海南
                  name: "", // 品种名称
                  nature: 1, // 审定机构性质 1:国审 2:省审 3:备案
                  otherSituation: "", // 其他推广情况
                  salesVolume: null, // 上一年度销售额
                  sort: 0
                }
              ]
            }
          ],  // 成果信息删除中
          investmentInfo: {
            // 投入信息
            facilities: null,
            // 基础设施投入
            manage: null,
            // 管理投入
            other: null,
            // 其他投入
            scientific: null
            // 科研生产投入
          },  // 投入信息
          achievementInfoList: [
            // 基地信息数组
            {
              cropName: "",
              cropId: "",
              // 作物名称
              sort: 0,
              varietiesInfoList: [
                // 品种信息List
                {
                  countryArea: null, // 累计推广面积-全国
                  hainanArea: null, //累计推广面积-海南
                  name: "", // 品种名称
                  nature: null, // 审定机构性质 1:国审 2:省审 3:备案
                  otherSituation: "", // 其他推广情况
                  salesVolume: null, // 上一年度销售额
                  sort: 0
                }
              ]
            }
          ] // 成果信息
        },
        breeding_list: [
          {
            // 育种类型列表
            nanfanVariety: '', // 南繁品种
            nanfanType: '', // 南繁类型
            nanfanDirection: '', // 南繁方向
            transgenicManagement: '' // 转基因管理
          }
        ],
        group_list: [],
        role_list,
        base_columns: [],
        labAddress: {
          disabled: false,
          provinceId: '',
          cityId: '',
          districtId: ''
        },
        cityDistrictVillage: {
          disabled: false,
          cityId: '',
          districtId: '',
          villagesId: '',
          provinceId: '00512a77b71d4215b81b625ce8702c55'
        },
        NanfanAddress: {
          disabled: false,
          cityId: '',
          districtId: '',
          villagesId: '',
          provinceId: '00512a77b71d4215b81b625ce8702c55'
        },
        moduleList: {
          module: 'nanfanRecords',
          subModule: 'personRecord'
        },
        dialogFormVisible: false,
        addBaseInfo: {
          baseName: '', // 基地名称
          company: '', // 所属单位
          person: '', // 负责人
          personPhone: '' // 负责人电话
        },
        formLabelWidth: '120px',
        baseFalg: false,
        submitStatus: true,
        baseType,
        buildType,
        nanfanType,
        cropInfoListType,
        activityType,
        breadLists,
        laborUnitPrice,
        excel: [], //excel数据
        landContractImg: [], // 基地地图
        landPlaneImg: [], // 基地地图
        planeImg: [], // 基地地图
        procedureImg: [], // 基地地图
        AreaType: [] // 新增配套设施类型
      };
    },
    created() {
      this.type = JSON.parse(this.$route.query.type);
      this.direction = JSON.parse(this.$route.query.direction);
    },
    mounted() {
      // 获取路由传递参数
      this.getCropList();
      this.id = this.$route.query.id;
      if (this.id) {
        this.getData();
      }
      if (this.direction.value == 2) {
        this.breadLists[2].name = '生产信息';
      } else {
        this.breadLists[2].name = '活动信息';
      }
      // 处理面包屑
      this.breadList.push(
        this.direction,
        this.type,
        this.breadLists[this.active]
      );
      this.data.basisInfo.nanfanDirection = this.direction.value;
      this.data.basisInfo.registerProperty = this.type.value;
      this.data.basisInfo.year = this.$route.query.date;
      this.getGroupList();
    },
    methods: {
      // 改变成果信息
      changeRecropName(val, index) {
        this.cropInfoListType.map((item, idx) => {
          if (item.id === val){
            this.data.resultInfoList[index].cropId = item.id;
            this.data.resultInfoList[index].cropName = item.name;
          }
        });
      },
      // 改变作物信息
      changecropName(val, index) {
        this.cropInfoListType.map((item, idx) => {
          if (item.id === val){
            this.data.cropInfoList[index].cropId = item.id;
            this.data.cropInfoList[index].cropName = item.name;
          }
        });
      },
      // 是否转基因
      // changeIsTransgene(val, index) {
      //   console.log(val)
      //   console.log(index)
      //   this.cropInfoListType.map((item, idx) => {
      //     if (val){
      //       this.data.cropInfoList[index].isTransgene = 1;
      //     } else {
      //       this.data.cropInfoList[index].isTransgene = 0;
      //     }
      //   });
      // },
      // 获取作物名称列表
      async getCropList() {
        const res = await this.$fetchData.fetchPost({}, this.$fetchApi.getCroplist.api, 'json');

        try {
          if (res.code === '200') {
            this.cropInfoListType = res.result;
          }
        } catch (e) {
          console.log(e, 'error');
        }
      },
      // 注册地址
      getNanfanRegister(val) {
        console.log(val);
        this.data.basisInfo.registerProvinceId = val[0].id ? val[0].id : '';
        this.data.basisInfo.registerProvinceName = val[0].name ? val[0].name : '';
        this.data.basisInfo.registerCityId = val[1].id ? val[1].id : '';
        this.data.basisInfo.registerCityName = val[1].name ? val[1].name : '';
        this.data.basisInfo.registerDistrictId = val[2].id ? val[2].id : '';
        this.data.basisInfo.registerDistrictName = val[2].name ? val[2].name : '';
        this.data.basisInfo.RegistNanfanAddress = 'RegistNanfanAddress';
      },
      // 南繁地址三级联动
      getNanfanAddress(val) {
        console.log(val);
        this.data.basisInfo.nanfanProvinceId = val[0].id ? val[0].id : '';
        this.data.basisInfo.nanfanProvinceName = val[0].name ? val[0].name : '';
        this.data.basisInfo.nanfanCityId = val[1].id ? val[1].id : '';
        this.data.basisInfo.nanfanCityName = val[1].name ? val[1].name : '';
        this.data.basisInfo.nanfanDistrictId = val[2].id ? val[2].id : '';
        this.data.basisInfo.nanfanDistrictName = val[2].name ? val[2].name : '';
        this.data.basisInfo.changeNanfanAddress = 'changeNanfanAddress';
      },
      //新增重点人员信息
      addPerson() {
        this.data.basisInfo.personnelInfoList.push({
          activityEndDate: '',
          //南繁活动结束时间
          activityStartDate: '',
          // 南繁活动开始时间
          name: '', // 姓名
          phoneNumber: '', // 联系电话
          position: '', // 职务职称
          sort: 0 // 排序
        });
      },
      /** 新增设备 */
      addNewequipment(index) {
        this.data.baseInfoList[index].equipmentInfoList.push(JSON.parse(JSON.stringify(equipmentInfoList)));
      },
      /** 删除基地 - 设备 */
      _remove_equipmentInfo(index, number) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.data.baseInfoList[index].equipmentInfoList.splice(number, 1);
        });
      },
      // 更改配套设施类型
      addenclosingwall(index) {
        const indexa = (this.data.baseInfoList[index].facilitiesInfoList).findIndex((item, index) => {
          return item.type === 1;
        });
        if (indexa > -1) {
          this.data.baseInfoList[index].facilitiesInfoList.splice(indexa, 1);
        } else {
          this.data.baseInfoList[index].facilitiesInfoList.push({
            'area': '',
            // 面积
            'buildTime': '',
            // 建设时间
            'isRecord': true,
            // 是否备案 1:是 0:否
            'recordTime': '',
            // 备案时间
            'type': 1
            // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
          });
        }
      },
      addRoad(index) {
        const indexa = (this.data.baseInfoList[index].facilitiesInfoList).findIndex((item, index) => {
          return item.type === 2;
        });
        if (indexa > -1) {
          this.data.baseInfoList[index].facilitiesInfoList.splice(indexa, 1);
        } else {
          this.data.baseInfoList[index].facilitiesInfoList.push({
            'area': '',
            // 面积
            'buildTime': '',
            // 建设时间
            'isRecord': true,
            // 是否备案 1:是 0:否
            'recordTime': '',
            // 备案时间
            'type': 2
            // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
          });
        }
      },
      addSunburn(index) {
        const indexa = (this.data.baseInfoList[index].facilitiesInfoList).findIndex((item, index) => {
          return item.type === 3;
        });
        if (indexa > -1) {
          this.data.baseInfoList[index].facilitiesInfoList.splice(indexa, 1);
        } else {
          this.data.baseInfoList[index].facilitiesInfoList.push({
            'area': '',
            // 面积
            'buildTime': '',
            // 建设时间
            'isRecord': true,
            // 是否备案 1:是 0:否
            'recordTime': '',
            // 备案时间
            'type': 3
            // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
          });
        }
      },
      addgreenhouse(index) {
        const indexa = (this.data.baseInfoList[index].facilitiesInfoList).findIndex((item, index) => {
          return item.type === 4;
        });
        if (indexa > -1) {
          this.data.baseInfoList[index].facilitiesInfoList.splice(indexa, 1);
        } else {
          this.data.baseInfoList[index].facilitiesInfoList.push({
            'area': '',
            // 面积
            'buildTime': '',
            // 建设时间
            'isRecord': true,
            // 是否备案 1:是 0:否
            'recordTime': '',
            // 备案时间
            'type': 4
            // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
          });
        }
      },
      addother(index) {
        const indexa = (this.data.baseInfoList[index].facilitiesInfoList).findIndex((item, index) => {
          return item.type === 5;
        });
        if (indexa > -1) {
          this.data.baseInfoList[index].facilitiesInfoList.splice(indexa, 1);
        } else {
          this.data.baseInfoList[index].facilitiesInfoList.push({
            'area': '',
            // 面积
            'buildTime': '',
            // 建设时间
            'isRecord': true,
            // 是否备案 1:是 0:否
            'recordTime': '',
            // 备案时间
            'type': 5
            // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
          });
        }
      },
      getTypeChange(value, index) {
        this.data.baseInfoList[index].facilitiesInfoList.push({
          'area': '',
          // 面积
          'buildTime': '',
          // 建设时间
          'isRecord': true,
          // 是否备案 1:是 0:否
          'recordTime': '',
          // 备案时间
          'type': 0
          // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
        });
      },
      // 新增建筑信息
      addBuilding(index) {
        this.data.baseInfoList[index].architectureInfoList.push(JSON.parse(baseInfoListLists[0].architectureInfoList));
      },
      /** 删除基地 - 建筑信息 */
      _remove_architectureInfo(index, number) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.data.baseInfoList[index].architectureInfoList.splice(number, 1);
        });
      },
      // 区域面积种类多选
      getAreaChange(val, index) {
        console.log(val, index);
        console.log(this.data.baseInfoList[index].AreaChange);
      },
      // 设置合同开始时间结束时间
      setContractTime(time, index) {
        this.data.basisInfo.contractEndDate = time[0]; // 合同开始时间；
        this.data.basisInfo.contractStartDate = time[1]; // 合同结束时间；
      },
      // 新增基地
      addList() {
        this.data.baseInfoList.push(JSON.parse(JSON.stringify(baseInfoListLists))[0]);
      },
      /** 删除基地 */
      _remove_baseInfo(index) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.data.baseInfoList.splice(index, 1);
        });
      },
      // 对解析的Excel数据进行处理
      fixExcel(o) {
        if (o instanceof Array) {
          for (let i = o.length - 1; i >= 0; i--) {
            if (Object.keys(o[i]).length < 6) {
              o.splice(i, 1);
            }
          }
        }
        o.forEach((item, index) => {
          let role = ['其他', '科研', '后勤', '服务'].indexOf(item['角色']);
          if (role === -1) role = '';
          console.log(role);
          this.$refs.fileExport.value = '';
          this.data.basisInfo.personnelInfoList.push({
            identityCard: item['身份证'],
            name: item['姓名'],
            nanfanAddress: item['南繁住址'],
            otherRole: item['角色-其他'],
            phoneNumber: item['联系电话'],
            position: item['职务职称'],
            role
          });
        });
      },
      // 监听change事件解析Excel
      fileExport(e) {
        const target = e.target.files[0];
        if (target.name.indexOf('.xlsx') === -1) {
          return this.$message('导入的文件必须是Excel');
        }
        const fileReader = new FileReader();
        fileReader.onload = ev => {
          try {
            var data = ev.target.result,
              workbook = XLSX.read(data, {
                type: 'binary'
              }), // 以二进制流方式读取得到整份excel表格对象
              persons = []; // 存储获取到的数据
          } catch (e) {
            return;
          }

          // 表格的表格范围，可用于判断表头是否数量是否正确
          let fromTo = '';
          // 遍历每张表读取
          for (const sheet in workbook.Sheets) {
            if (workbook.Sheets.hasOwnProperty(sheet)) {
              fromTo = workbook.Sheets[sheet]['!ref'];
              persons = persons.concat(
                XLSX.utils.sheet_to_json(workbook.Sheets[sheet])
              );
              // break; // 如果只取第一张表，就取消注释这行
            }
          }
          this.fixExcel(persons);
        };

        // 以二进制方式打开文件
        fileReader.readAsBinaryString(target);
      },

      async getData() {
        const api = this.$fetchApi.nanfanRegisterDetail.api + this.id;
        const data = await this.$fetchData.fetchGet({}, api, 'json');
        if (data.code === '200') {
          this.data = data.result;
          console.log(this.data);
          this.cityDistrictVillage = {
            disabled: false,
            cityId: this.data.baseInfoList.cityId,
            districtId: this.data.baseInfoList.districtId,
            villagesId: this.data.baseInfoList.villageId,
            provinceId: '00512a77b71d4215b81b625ce8702c55'
          };
          this.year = `${this.data.basisInfo.year}`;
          this.data.cropInfoList.forEach(item => {
            this.$set(item, 'dateValue', [
              item.plantEndTime,
              item.plantStartTime
            ]);
          });
          if (!this.data.basisInfo.personnelInfoList) {
            this.$set(this.data.basisInfo, 'personnelInfoList', [
              {
                identityCard: '',
                name: '',
                nanfanAddress: '',
                otherRole: '',
                phoneNumber: JSON.parse(localStorage.getItem('loginaccount'))
                  .phoneNumber,
                position: '',
                role: ''
              }
            ]);
          }
          this.landContractImg = this.data.baseInfoList.landContractImg;
          this.landPlaneImg = this.data.baseInfoList.landPlaneImg;
          this.planeImg = this.data.baseInfoList.planeImg;
          this.procedureImg = this.data.baseInfoList.procedureImg;
          this.data.baseInfoList.forEach(item => {
            item.AreaChange = [];
            item.baseDistribution = [];
            if (item.coreArea) {
              item.baseDistribution.push(1);
            }
            if (item.distributionArea) {
              item.baseDistribution.push(2);
            }
          });
          this.data.resultInfoList = data.result.achievementInfoList;
        }
      },
      // 删除品种
      _remove_variety(val, index) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          val.varietiesInfoList.splice(index, 1);
        });
      },
      // 新增品种
      _addVariety(item) {
        item.varietiesInfoList.push({
          areaHainan: '',
          areaNational: '',
          salesVolume: '',
          reviewName: '',
          situation: '',
          varietyName: ''
        });
      },
      //删除成果
      _remove_result(index) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.data.resultInfoList.splice(index, 1);
          this.initIndex2--;
        });
      },
      // 新增成果
      addResult() {
        this.data.resultInfoList.push({
          cropName: "",
          cropId: "",
          // 作物名称
          sort: '',
          varietiesInfoList: [
            // 品种信息List
            {
              countryArea: '', // 累计推广面积-全国
              hainanArea: '', //累计推广面积-海南
              name: "", // 品种名称
              nature: '', // 审定机构性质 1:国审 2:省审 3:备案
              otherSituation: "", // 其他推广情况
              salesVolume: '', // 上一年度销售额
              sort: ''
            }
          ]
        });
        this.initIndex2++;
      },
      // 处理时间段
      getActivityDate(val, item) {
        item.plantStartTime = val[0]; // 开始时间
        item.plantEndTime = val[1]; // 结束时间
        console.log(item);
      },
      // 删除作物
      _remove_message(index) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.data.cropInfoList.splice(index, 1);
          this.initIndex1--;
        });
      },
      // 新增作物
      _addMessage() {
        this.data.cropInfoList.push(  {
          breedingNumber: '',
// 育种组合数
          cropName: '',
// 作物名称
          cropId: '',
// 作物名称
          isTransgene: '',
// 是否转基因 1:是 0:否
          plantArea: '',
// 种植面积
          plantEndTime: '',
// 种植结束时间
          plantStartTime: '',
// 种植开始时间
          project: '',
// 项目 1:加代 2:扩繁 3:制种 4:鉴定 5:资源保存 6:其他
          sort: '',
          varietiesNumber: '',
// 品种数
          yield: ''
//   产量
        });
        this.initIndex1++;
      },
      // 删除人员信息
      remove_message(index) {
        this.$confirm('是否删除？', '友情提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.data.basisInfo.personnelInfoList.splice(index, 1);
          this.initIndex--;
        });
      },
      // 新增人员信息
      addMessage() {
        this.data.basisInfo.personnelInfoList.push({
          identityCard: '',
          name: '',
          nanfanAddress: '',
          otherRole: '',
          phoneNumber: '',
          position: '',
          role: ''
        });
        this.initIndex++;
      },
      // 选择基地性质
      choseBaseType(val) {
        console.log(val, '基地性质');
      },
      //上一步，下一步
      stepTo(index) {
        if (index === 1){
          this.active += 1;
        } else {
          this.active -= 1;
        }
      },
      /** 获取备案组别 */
      async getGroupList() {
        const api = this.$fetchApi.nanfanRecordGroup.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200') {
          this.group_list = data.result;
        }
      },
      /** 根据组别获取角色 */
      async getRoleList(id) {
        const api = this.$fetchApi.getRoleById.api + '/' + id;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200') {
          this.role_list = JSON.parse(JSON.stringify(data.result));
        }
      },
      // 选择组别
      choseGroup(data) {
        this.getRoleList(data);
      },
      addressInfo(val) {
        this.data.otherProvinceId = val[0].id; // 居住地址 省id
        this.data.otherCityId = val[1].id; // 居住地址 市id
        this.data.otherDistrictId = val[2].id; // 居住地址 区id
      },
      // 选择时间 时间处理
      changNanfanYear(data) {
        this.data.basisInfo.year = yyyymmdd(data).slice(0, 4) * 1;
      },
      // 选择时间 时间处理
      changNanfanTime(data) {
        this.data.baseInfoList.buildConstructionDate = yyyymmdd(data);
      },
      /** 返回市区村信息 */
      villageInfo(val, index) {
        this.data.baseInfoList[index].provinceId = val[0].id ? val[0].id : '';
        this.data.baseInfoList[index].provinceName = val[0].name ? val[0].name : '';
        this.data.baseInfoList[index].cityId = val[1].id ? val[1].id : '';
        this.data.baseInfoList[index].cityName = val[1].name ? val[1].name : '';
        this.data.baseInfoList[index].districtId = val[2].id ? val[2].id : '';
        this.data.baseInfoList[index].districtName = val[2].name ? val[2].name : '';
        this.data.baseInfoList[index].villageId = val[3].id ? val[3].id : '';
        this.data.baseInfoList[index].villageName = val[3].name ? val[3].name : '';
        this.baseFalg = false;
        const params = {
          pageNum: 1,
          pageSize: 1000,
          provinceId: '',
          cityId: '',
          districtId: '',
          villageId: ''
        };
        if (!val[0]) {
          params.provinceId = val[0].id ? val[0].id : '';
        } else if (!val[1]) {
          params.cityId = val[1].id ? val[1].id : '';
        } else if (!val[2]) {
          params.districtId = val[2].id ? val[2].id : '';
        } else {
          params.villageId = val[3].id ? val[3].id : '';
        }
        this.data.baseInfoList.id = '';
        this.getBaseList(params);
      },
      /** 获取基地列表 */
      async getBaseList(params) {
        const api = this.$fetchApi.baseScreeningContent.api;
        const data = await this.$fetchData.fetchPost(params, api, 'json');
        if (data.code === '200') {
          const obj = [
            {
              baseId: '',
              baseName: '暂无数据'
            }
          ];
          this.base_columns = data.result ? data.result.rows : obj;
        }
      },
      baseConfirm(data, item, index) {
        this.base_columns.forEach((itm, idx) => {
          if (itm.id === data) {
            this.data.baseInfoList[index].baseName = itm.baseName;
            this.data.baseInfoList[index].baseId = itm.id;
            this.data.baseInfoList[index].lat = itm.latitude;
            this.data.baseInfoList[index].lng = itm.longitude;
          }
        });
        this.data.baseInfoList[index].baseId = data;
        this.getBaseDetailInfo(data, index);
      },
      /** 新增基地 */
      addBase() {
        if (!this.data.baseInfoList.cityId) {
          Message('请先选择基地地址');
          return true;
        }
        this.dialogFormVisible = true;
      },
      /** 增加育种类型 */
      addBreeding() {
        this.breeding_list.push({
          nanfanVariety: '', // 南繁品种
          nanfanType: '', // 南繁类型
          nanfanDirection: '', // 南繁方向
          transgenicManagement: '' // 转基因管理
        });
      },
      /** 删除育种类型 */
      deleteBreeding(index) {
        this.breeding_list.splice(index, 1);
      },
      /** 获取基地具体信息 */
      async getBaseDetailInfo(id, index) {
        const api = this.$fetchApi.nanfanRegisterBase.api + id;
        const data = await this.$fetchData.fetchGet({}, api, 'json');
        if (data.code === '200') {
          this.data.baseInfoList[index].baseArea = data.result.baseArea || '';
          this.data.baseInfoList[index].baseAddress = data.result.baseAddress || '';
          this.data.baseInfoList[index].baseNature = data.result.baseNature || 0;
          this.data.baseInfoList[index].baseId = data.result.id;
          this.landContractImg = this.data.baseInfoList[index].landContractImg;
          this.landPlaneImg = this.data.baseInfoList[index].landPlaneImg;
          this.planeImg = this.data.baseInfoList[index].planeImg;
          this.procedureImg = this.data.baseInfoList[index].procedureImg;
        }
      },
      // 建设时间
      changNuildingTime(data) {
        this.data.baseInfoList.buildingTime = yyyymmdd(data);
      },
      // 获取图片
      getImgList(fileList) {
        const files = [];
        if (fileList.length > 0) {
          fileList.forEach(item => {
            files.push({
              filePath: item.filePath,
              fileId: item.id
            });
          });
        }
        return files;
      },
      // 平面照片
      changePlaneImgUrl(fileList, index) {
        // this.data.baseInfoList.planeImg = this.getImgList(fileList);
        this.data.baseInfoList[index].planeImg = fileList[0].filePath;
      },
      // 土地流转合同
      changelandTransferContract(fileList, index) {
        this.data.baseInfoList[index].landContractImg = fileList[0].filePath;
      },
      // 生产设施
      changeImgList(fileList) {
        this.data.baseInfoList.procedureImg = this.getImgList(fileList);
      },
      // 新增地址
      onSureBase() {
        if (new Date() - this.timeOut < 1000) {
          return true;
        }
        this.timeOut = new Date();
        this.$refs.addBaseInfo.validate(valid => {
          if (valid) {
            this.addBaseCallback();
          } else {
            window.scroll(0, 400);
            return false;
          }
        });
      },
      async addBaseCallback() {
        const loadingInstance = Loading.service({
          fullscreen: true,
          background: 'rgba(0,0,0,.4)'
        });
        const params = JSON.parse(JSON.stringify(this.addBaseInfo));
        params.cityId = this.data.baseInfoList.cityId;
        params.districtId = this.data.baseInfoList.districtId;
        params.villageId = this.data.baseInfoList.villageId;
        const api = this.$fetchApi.nanfanRecordAddBse.api;
        const data = await this.$fetchData.fetchPost(params, api, 'json');
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        if (data.code === '200') {
          this.dialogFormVisible = false;
        }
      },
      /** 新增完成 */
      addSubmit() {
        if (!this.submitStatus) {
          return true;
        }
        this.$refs.personRecord3.validate(valid => {
          if (valid) {
            this.judgeBlackInfo();
          } else {
            window.scroll(0, 400);
            return false;
          }
        });
      },
      async addRecord() {
        const datas = JSON.parse(JSON.stringify(this.data));
        this.data.achievementInfoList = datas.resultInfoList;
        // console.log(this.data.achievementInfoList)

        const loadingInstance = Loading.service({
          fullscreen: true,
          background: 'rgba(0,0,0,.4)'
        });
        const params = JSON.parse(JSON.stringify(this.data));
        delete params.resultInfoList;

        // 育种信息
        // 编辑还是删除
        const api = !this.id
          ? this.$fetchApi.nanfanRegisterAdd.api
          : this.$fetchApi.nanfanRegisterEditor.api;
        const data = await this.$fetchData.fetchPost(
          filter_empty_value(params),
          api,
          'json'
        );
        this.$nextTick(() => {
          // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        if (data.code === '200') {
          this.submitStatus = false;
          // 提交成功删除缓存
          this.$store.commit('removeCache', 'nanfanbase');
          MessageBox.confirm(
            '管理人员在5个工作日内对登记备案内容进行核实，请耐心等待！',
            '提示',
            {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'success'
            }
          )
            .then(() => {
              this.$router.go(-1);
            })
            .catch(err => this.active++);

          this.$emit('changeNature', 1);
        }
      },
      // 判断是否是黑名单
      async judgeBlackInfo() {
        const api = this.$fetchApi.judgeBlackInfo.api;
        const data = await this.$fetchData.fetchPost({}, api, 'json');
        if (data.code === '200') {
          if (data.result === 1) {
            this.checkModuleData();
          } else if (data.result === 0) {
            Message('你在黑名单内,暂不能进入服务');
          }
        }
      },
      // 判断备案时间是否开启
      async checkModuleData() {

        const checkModuleApi =
          this.$fetchApi.checkModule.api + '/be9b8aafd42546e1a5ee86b34e16cdd6';
        const checkModuleData = await this.$fetchData.fetchPost(
          {},
          checkModuleApi,
          'json'
        );
        if (checkModuleData.code === '200') {
          if (checkModuleData.result.status === 0) {
            this.addRecord();
          } else if (checkModuleData.result.status === 1) {
            Message('后台已关闭');
          } else if (checkModuleData.result.status === 2) {
            Message('现在不在备案时间');
          }
        }
      }
    },
    watch: {
      active(val) {
        val < 4 && this.breadList.splice(this.breadList.length - 1, 1, this.breadLists[this.active]);
      },
      data: {
        handler(val) {
          this.data.basisInfo.workerNumber = parseInt(this.data.basisInfo.otherWorkerNumber) + parseInt(this.data.basisInfo.hainanWorkerNumber);
          this.data.basisInfo.personalNumber = parseInt(this.data.basisInfo.workerNumber) + parseInt(this.data.basisInfo.managePersonalNumber) + parseInt(this.data.basisInfo.skillPersonalNumber) + parseInt(this.data.basisInfo.otherPersonalNumber);
          this.data.basisInfo.otherWorkerNumber = parseInt(this.data.basisInfo.otherWorkerNumber);
        },
        deep: true
      }
    }
  };
</script>
<style lang="less" scoped>
  @import "./common.less";

.inp-width{
  /deep/.el-input__inner{
    width: 280px;
  }
}
.sub-title{
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  padding-left: 12px;
  position: relative;
  margin-bottom: 10px;
  &:before{
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    top: 5px;
    bottom: 5px;
    left: 0;
    border-left: 5px solid rgb(0, 128, 0);
  }
  &.sub-blue{
    &:before{
      border-left: 5px solid rgb(45, 140, 240);
    }
  }
}
  .source {
    width: 1140px;
    background-color: #b4d1ff;
    border-radius: 4px;
    font-size: 18px;
    color: #448afc;
    padding: 20px;
    margin: 0 auto;
  }

  .operation {
    span {
      width: 80px;
      height: 36px;
      background-color: #ff5454;
      border-radius: 2px;
      display: inline-block;
      color: #fff;
      line-height: 36px;
      text-align: center;
    }

    .add_breed {
      background-color: #448afc;
      margin-left: 20px;
    }
  }

  .breedsTitle {
    padding: 20px 110px 10px 30px;
  }

  .contentBg {
    width: 1135px;
    background-color: #f6f6f6;
    border-radius: 4px;
    margin: 0 auto;
    padding-bottom: 20px;

    .person-btn {
      img {
        display: block;
        margin: 0 auto;
        width: 26px;
        height: 26px;
      }

      p {
        width: 48px;
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(75, 81, 98, 1);
        line-height: 24px;
      }
    }
  }

  .padding {
    margin-top: 20px;
  }

  .addBase {
    padding: 10px 0 20px;
    margin-left: 70px;
    color: #666;

    span:nth-child(2) {
      color: #448afc;
    }
  }

  .equipment {
    /deep/ .el-input__inner {
      width: 200px;
    }

    /deep/ .el-select {
      width: 200px;
      margin-right: 42px;
    }

    /deep/ .el-input {
      width: 200px;
    }
  }

  .importantePerson {
    background-color: rgba(171, 171, 171, 0.46);
    padding: 2rem .5rem;
    margin-bottom: 1rem;
    border-radius: .5rem;
  }

  img {
    width: 120px;
    height: 120px;
  }
  .areaItem{
    display: inline-block;

    /deep/  .el-input__inner{
      width: 150px;
    }
    /deep/ .detail-address .el-input__inner{
      width: 290px ;
    }
    /deep/ .el-select{
      width:150px;
      margin-right:42px;
    }
    /deep/ .el-input{
      width:150px;
    }
  }
</style>
